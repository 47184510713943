<template>
  <v-container grid-list-md pt-0>
    <div style="margin-top:17rem !important" v-if="isLoading">
      <v-layout row wrap flex justify-center align-center fill-height>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
    </div>
    <div class="" v-if="!isLoading">
      <v-layout row wrap align-center justify-center>
        <v-flex md12 xs12 sm12 style="text-align:center">
          <img
            src="../assets/images/RequestTreeStep_newlogo.png"
            alt=""
            width="700"
            aspect-ratio="1"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      totalPlant: "",
      listPlant: [],
      isSort: true,
      isLoading: true,
      numberOfPeopleByTotlePlant: "",
      userRegister: "",
    };
  },
  created() {
    this.isLoading = true;
    this.queryPlantTopFifteen();
    this.$emit("activeMenu", "howto_request");
  },
  computed: {
    filteredList() {
      return this.listPlant.filter((post) => {
        return post.plant_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    queryPlantTopFifteen() {
      this.axios
        .post(process.env.VUE_APP_IPFLASK + "/queryPlantTopFifteen")
        .then((response) => {
          // console.log(response.data);
          this.listPlant = response.data;
          this.isLoading = false;
        });
    },
    commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      return val;
    },
    sort() {
      if (this.isSort) {
        this.isSort = false;
        return this.listPlant.sort(function(a, b) {
          return b.sumPlant - a.sumPlant;
        });
      } else {
        this.isSort = true;
        return this.listPlant.sort(function(a, b) {
          return a.sumPlant - b.sumPlant;
        });
      }
    },
  },
};
</script>

<style scoped>
.lineheight {
  line-height: 0.65 !important;
}
.lineheight30 {
  line-height: 40px !important;
}
</style>

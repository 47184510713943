<template lang="html">
  <v-container grid-list-md align-center justify-center pt-0 pl-0 pr-0>
    <ComponentHowto @activeMenu="activeMenu" />
  </v-container>
</template>

<script>
import ComponentHowto from "@/components/ComponentHowto";
export default {
  components: {
    ComponentHowto,
  },
  data() {
    return {
      active: "",
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push(link);
    },
    activeMenu(status) {
      this.$emit("activeMenu", status);
    },
  },
};
</script>
